import { useState, useEffect } from 'react';
import '../index.css';
import { Message, Form, Button, Header, Container, Icon, Segment } from 'semantic-ui-react';
import RequestHelpers from '../../../../helpers/requestHelpers';
import { useHistory } from 'react-router-dom';

const CreateEmployee = function(props) {
  const history = useHistory();
  const [firstName, setFirstName]             = useState('');
  const [lastName, setLastName]               = useState('');
  const [email, setEmail]                     = useState('');
  const [identifier, setIdentifier]           = useState('');
  const [emailError, setEmailError]           = useState(false);
  const [errors, setErrors]                   = useState([]);

  const getStateData = () => {
    return {
      first_name: firstName,
      last_name: lastName,
      email: email,
      identifier: identifier
    }
  }

  const handleOnChange = (e) => {
    switch(e.target.name) {
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "identifier":
        setIdentifier(e.target.value);
        break;
      default:
        break;
    }
  }

  const validEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return(re.test(String(email).toLowerCase()));
  }

  const handleValidation = () => {
    var errorList = [];
    setErrors([]);
    setEmailError(false);

    if(!validEmail()) {
      setEmailError(true);
      errorList.push("Email format is invalid");
    }

    setErrors(errorList);

    return errorList.length;
  }

  const handleSubmit = (e) => {
    if(handleValidation() === 0) {
      RequestHelpers.postData("/api/v1/employees", getStateData())
        .then(res => {
          if(res.status === 200) {
            history.push("/admin/employees");
          }
        })
    }
  }

  useEffect(() => {
    props.setActiveItem("create_employee");
  }, [props])

  return(
    <div>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='plus' /> Create Employee
        </Header>
      </Segment>
      
      <Container className="table-container" fluid>
        <Message 
          hidden={errors.length === 0}
          negative
          header='Form Errors'
          list={errors}
        ></Message>
        <Segment inverted>
          <Form onSubmit={handleSubmit} inverted>
            <Form.Input onChange={handleOnChange} required fluid label='First name' placeholder='First name' name='firstName' />
            <Form.Input onChange={handleOnChange} required fluid label='Last name' placeholder='Last name' name='lastName' />
            <Form.Input onChange={handleOnChange} required error={emailError} fluid label='Email' placeholder='Email' name='email' />
            <Form.Input onChange={handleOnChange} required fluid label='Identifier' placeholder='Identifier' name='identifier' />
            <Button fluid type='submit'>Submit</Button>
          </Form>
        </Segment>
      </Container>
    </div>
  )
}

export default CreateEmployee;
