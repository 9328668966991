import { useEffect } from 'react';
import './index.css';

const Dashboard = function(props) {
  useEffect(() => {
    props.setActiveItem("view_dashboard");
  })

  return(
    <div className="Dashboard">
      <span>Dashboard Coming Soon...</span>
    </div>
  )
}

export default Dashboard;
