import { useEffect, useState } from 'react';
import { Segment, Loader, Transition, Header, Form, Button, Grid, Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import RequestHelpers from '../../helpers/requestHelpers';
import './index.css';

const AccountSetup = function(props) {
  const history = useHistory();
  const [started, setStarted]     = useState(false);
  const [visible1, setVisible1]   = useState(false);
  const [visible2, setVisible2]   = useState(false);
  const [visible3, setVisible3]   = useState(false);
  const [visible4, setVisible4]   = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [user, setUser] = useState({});

  const setVisibleStep = (step) => {
    switch(step) {
      case 1:
        setVisible2(false);
        setVisible3(false);
        setVisible4(false);
        setTimeout(() => {
          setVisible1(true);
        }, 300);
        break;
      case 2:
        setVisible1(false);
        setVisible3(false);
        setVisible4(false);
        setTimeout(() => {
          setVisible2(true);
        }, 300);
        break;
      case 3:
        setVisible1(false);
        setVisible2(false);
        setVisible4(false);
        setTimeout(() => {
          setVisible3(true);
        }, 300);
        break;
      case 4:
        setVisible1(false);
        setVisible2(false);
        setVisible3(false);
        setTimeout(() => {
          setVisible4(true);
        }, 300);
        break;
      default:
        break;
    }
  }

  const handlePassword = function(e) {
    setPassword(e.target.value);
  } 

  const handleConfirmPassword = function(e) {
    setConfirmPassword(e.target.value);
  } 

  const handleSubmit = (e) => {
    setVisibleStep(4);
    if(password === confirmPassword)
    {
      user.password = password;
      RequestHelpers.postData(`/api/v1/users/verify/${props.match.params.token}/password`, user)
        .then(res => {
          if(res.status === 404)
          {
            setShowError(true);
          }
          else if(res.status === 200)
          {
            setTimeout(() => {
              history.push("/login");
            }, 2000)
          }
        })
    }
    else
    {
      setVisibleStep(3);
      setShowError(true);
    }
    

    e.preventDefault();
  }

  const lookupVerification = () => {
    return RequestHelpers.getData(`/api/v1/users/verify/${props.match.params.token}`)
  }

  const start = () => {
    setVisibleStep(1);

    setTimeout(() => {
      lookupVerification()
        .then((res) => {
          if(res.status === 200) {
            res.json().then((data) => {
              setUser(data);
              setVisibleStep(2);
              setTimeout(() => {
                setVisibleStep(3);
              }, 2000)
            })
          }

          if(res.status === 404) {
            setVisibleStep(4);
            setTimeout(() => {
              history.push('/login');
            }, 1500)
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }, 1500)
  }

  useEffect(() => {
    return () => {
      if(!started) {
        setStarted(true);
        start();
      }  
    };
  })  
  
  return(
    <div className="AccountSetup">
      <Segment className="load-segment">
        <Transition duration={ [300, 300] } visible={visible1}>
          <Loader indeterminate inverted active size='massive'>Verifying Account</Loader>
        </Transition>

        <Transition duration={ [300, 300] } visible={visible2}>
          <Loader inverted active size='massive'>Verified!</Loader>
        </Transition>

        <Transition duration={ [300, 300] } visible={visible3}>
          <div>
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
              <Grid.Column style={{maxWidth: 450}}>
                <Header className="Header" as='h1' textAlign='center'>
                  Create Password  
                </Header>
                <Form onSubmit={handleSubmit} size='large'>
                  <Segment className="Segment" stacked>
                    <Message hidden={!showError} negative
                      header='Invalid Login'
                      list={[
                        'Please check your username/password and try again'
                      ]}
                    ></Message>

                    <Form.Input 
                      fluid icon='lock'
                      iconPosition='left'
                      placeholder='Password'
                      className="Input"
                      type='password'
                      required
                      onChange={handlePassword}
                    />

                    <Form.Input
                      fluid
                      icon='lock'
                      iconPosition='left'
                      placeholder='Confirm Password'
                      type='password'
                      required
                      onChange={handleConfirmPassword}
                    />

                    <Button fluid size='large'>
                      Submit
                    </Button>
                  </Segment>
                </Form>
              </Grid.Column>
            </Grid>
          </div>
        </Transition>

        <Transition duration={ [300, 300] } visible={visible4}>
          <Loader inverted active size='massive'>Redirecting to Login...</Loader>
        </Transition>
      </Segment>
    </div>
  )
}

export default AccountSetup;
