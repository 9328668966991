// Styles
import "./index.css";

// Libraries
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

// Components
import AccountSetup from "../AccountSetup";
import Admin        from "../Admin";
import Checkout     from "../Checkout";
import Loading      from "../Loading";
import Login        from "../Login";

// Helpers
import RequestHelpers from "../../helpers/requestHelpers";

// Capture pathname of the first requested route
// So the user can be redirected to it after login
const location = window.location.pathname;

const App = function() {
  const [loggedIn,     setLoggedIn]     = useState(false);     // Start in logged out state
  const [loading,      setLoading]      = useState(true);     // Show loader by default
  const [redirectPath, setRedirectPath] = useState(location); // Set outside of component

  const refreshSession = () => {
    RequestHelpers.postData("/api/v1/auth/refresh", {})
      .then(res => {
        if(res.status === 200 || res.status === 304) {
          setLoggedIn(true);
          setLoading(false);
        }
        else
        {
          setLoggedIn(false);
          setLoading(false);
        }
      })
  }

  useEffect(() => {
    // Validate session on every refresh
    refreshSession();

    // This is a work around for now so the
    // user won"t land back on the login page
    if(redirectPath === "/login" || redirectPath.startsWith("/account/verify")) {
      setRedirectPath("/admin/dashboard");
    }
  }, [setRedirectPath, redirectPath])

  

  return (
    <React.Fragment>
      <div className="App">
        <Router>
          <Switch>

            { loading ? <Loading /> : null }

            {/* Go to dashboard if user hits root */}
            <Route exact path="/">
              <Redirect to="/admin/dashboard" /> 
            </Route>

            <Route exact path="/login">
              { loggedIn && !loading ? <Redirect to={ redirectPath || "/admin/dashboard" } /> : <Login setLoggedIn={setLoggedIn} /> }
            </Route>

            <Route path="/admin">
              { loggedIn && !loading ? <Admin setLoggedIn={setLoggedIn} /> : <Redirect to={`/login?securePath=${redirectPath}`} /> }
            </Route>

            <Route exact path="/checkout">
              { loggedIn && !loading ? <Checkout /> : <Redirect to={`/login?securePath=${redirectPath}`} />}
            </Route>

            <Route exact path="/account/verify/:token" component={AccountSetup} />
          </Switch>
        </Router>
      </div>
    </React.Fragment>
  )  
}

export default App;