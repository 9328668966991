import { useEffect, useState } from 'react';
import './index.css';
import { Header, Container, Icon, Segment, Form, Input, Modal, Button, Grid, Divider, Message } from 'semantic-ui-react'
import RequestHelpers from '../../../../helpers/requestHelpers';

const InventoryProduct = function(props) {
  const [upc, setUPC]             = useState(null);
  const [product, setProduct]     = useState({});
  const [quantity, setQuantity]   = useState("1");
  const [open, setOpen]           = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    props.setActiveItem("inventory_product");
    document.getElementById("upc").focus();
  }, [props]);

  const handleOnUPCChange = (e) => {
    setUPC(e.target.value);
  }

  const handleOnQuantityChange = (e) => {
    setQuantity(e.target.value);
  }

  const handleAddRemove = (e) => {
    let updatedProduct = product;
    if(e.target.id === "add") {
      updatedProduct.stock = parseInt(updatedProduct.stock) + parseInt(quantity);
      RequestHelpers.putData(`/api/v1/products/${product.id}`, updatedProduct)
        .then((res) => { return(res.json()); })
        .then((result) => {
          document.getElementById("upc").value = "";
          document.getElementById("upc").focus();
          setShowError(false);
          setOpen(false);
        })
    }

    if(e.target.id === "remove") {
      updatedProduct.stock = parseInt(updatedProduct.stock) - parseInt(quantity);
      RequestHelpers.putData(`/api/v1/products/${product.id}`, updatedProduct)
        .then((res) => { return(res.json()); })
        .then((result) => {
          document.getElementById("upc").value = "";
          document.getElementById("upc").focus();
          setShowError(false);
          setOpen(false);
        })
    }
  }

  const handleKeyPadPress = (e) => {
    if(document.getElementById("quantity").value === "0") {
      document.getElementById("quantity").value = "";
    }

    if(document.getElementById("quantity").value.length === 1 && document.getSelection().baseNode === document.getElementById("quantity").parentElement && document.getSelection().type === "Range") {
      document.getElementById("quantity").value = "";
    }

    if(parseInt(e.target.innerHTML) >= 0 && parseInt(e.target.innerHTML) <= 9) {
      document.getElementById("quantity").value = document.getElementById("quantity").value+e.target.innerHTML;
    }

    if(e.target.innerHTML.startsWith('<i') || e.target.parentElement.innerHTML.startsWith('<i')) {
      document.getElementById("quantity").value = document.getElementById("quantity").value.substring(0, document.getElementById("quantity").value.length - 1);
    }

    if(document.getElementById("quantity").value === "") {
      document.getElementById("quantity").value = "0";
    }

    setQuantity(document.getElementById("quantity").value);
  }

  const handleOnOpen = () => {
    setOpen(true);
  }

  const handleOnClose = () => {
    document.getElementById("upc").value = "";
    document.getElementById("upc").focus();
    setShowError(false);
    setOpen(false);
  }

  const handleQuantityKeyUp = (e) => {
    if(e.target.value.length === 0 && e.which === 8) {
      e.target.value = 0;
    }
  }

  const handleQuantityKeyDown = (e) => {
    console.log(e);
    if(e.which >= 48 && e.which <= 57) {
      if(e.target.value.length === 1 && e.target.value === "0") {
        e.target.select();
      }
    }
  }

  const handleUPCSubmit = (e) => {
    RequestHelpers.getData(`/api/v1/products/lookup/${upc}`)
      .then((res) => {
        if(res.status === 200) {
          setOpen(true);
          return(res.json()); 
        }
        else
        {
          setShowError(true);
          return(null);
        }
      })
      .then((result) => {
        if(result) {
          setProduct(result);
          document.getElementById("quantity").value = "1";
          document.getElementById("quantity").select();
        }
        else
        {
          document.getElementById("upc").value = "";
          document.getElementById("upc").focus();
        }
      })
  }

  const handleCancel = (e) => {
    setOpen(false);
  }

  return(
    <div className="Inventory">
      <Segment className="header-segment" clearing>
        <Header inverted floated="left" as='h2'>
          <Icon inverted name='plus square outline' /> Inventory Product
        </Header>
      </Segment>
      
      <Container className="inventory-container" fluid>
        <Form inverted onSubmit={handleUPCSubmit}>
          <Form.Input onChange={handleOnUPCChange} label="UPC" id="upc" fluid action="Search" placeholder="xxxxxxx" />
        </Form>

        <Message hidden={!showError} negative>
          <Message.Header>UPC Not Found</Message.Header>
          <p>Please check with your administrator</p>
        </Message>
      </Container>

      <Modal onClose={handleOnClose} onOpen={handleOnOpen} open={open} >
        <Modal.Header>Add/Remove Inventory</Modal.Header>
        <Modal.Content className="InventoryModal">
          <Modal.Description>
            <Header textAlign="center" as="h4">
              {product.name}<br />
              Stock: {product.stock}
            </Header>
            <Divider />
            <Input className="quantity" type="number" id="quantity" onChange={handleOnQuantityChange} onKeyDown={handleQuantityKeyDown} onKeyUp={handleQuantityKeyUp} fluid size="massive" defaultValue={quantity} />
            <Divider />
            <Container>
              <Grid columns={3} divided>
                <Grid.Row>
                  <Grid.Column>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">1</Button>
                    </Segment>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">4</Button>
                    </Segment>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">7</Button>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">2</Button>
                    </Segment>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">5</Button>
                    </Segment>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">8</Button>
                    </Segment>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">0</Button>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">3</Button>
                    </Segment>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">6</Button>
                    </Segment>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">9</Button>
                    </Segment>
                    <Segment textAlign="center">
                      <Button onClick={handleKeyPadPress} fluid size="big">
                        <Icon name="long arrow alternate left" color="black" />
                      </Button>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns={2} divided>
                <Grid.Row>
                  <Grid.Column>
                    <Segment textAlign="center">
                      <Button onClick={handleAddRemove} negative id="remove" fluid size="massive">-</Button>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment textAlign="center">
                      <Button onClick={handleAddRemove} positive id="add" fluid size="massive">+</Button>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button fluid color='black' onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default InventoryProduct;
