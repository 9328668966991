const DateHelpers = {
  formatDate: (date) => {
    const parsedDate = new Date(date);
    const [month, day, year]       = [parsedDate.getMonth(), parsedDate.getDate(), parsedDate.getFullYear()];
    const [hour, minutes] = [parsedDate.getHours(), parsedDate.getMinutes()];

    const ampm = hour > 12 ? 'PM' : 'AM';
    const newHour = hour > 12 ? (hour-12) : hour;

    return(`${month}/${day}/${year} - ${newHour}:${minutes} ${ampm}`);
  }
}

export default DateHelpers;