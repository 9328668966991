import { useState, useEffect } from 'react';
import '../index.css';
import { Input, Message, Form, Button, Header, Container, Icon, Segment } from 'semantic-ui-react'
import RequestHelpers from '../../../../helpers/requestHelpers';
import { useHistory } from 'react-router-dom';

const EditUser = function(props) {
  const history = useHistory();
  const [userId, setUserId]                   = useState('');
  const [firstName, setFirstName]             = useState('');
  const [lastName, setLastName]               = useState('');
  const [email, setEmail]                     = useState('');
  const [password, setPassword]               = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors]                   = useState([]);

  const getStateData = () => {
    return {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password
    }
  }

  const getUser = () => {
    RequestHelpers.getData(`/api/v1/users/${props.match.params.userId}`)
      .then((res) => { return(res.json()); })
      .then((result) => {
        setUserId(result._id);
        setFirstName(result.first_name);
        setLastName(result.last_name);
        setEmail(result.email);
      })
  }

  const handleOnChange = (e) => {
    switch(e.target.name) {
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "confirm_password":
        setConfirmPassword(e.target.value);
        break;
      default:
        break;
    }
  }

  const validEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return(re.test(String(email).toLowerCase()));
  }

  const handleValidation = () => {
    var errorList = [];
    setErrors([]);

    if(!validEmail()) {
      errorList.push("Email format is invalid");
    }

    if(password !== confirmPassword) {
      errorList.push("Password and Confirm Password fields must match");
    }

    setErrors(errorList);

    return errorList.length;
  }

  const handleSubmit = (e) => {
    if(handleValidation() === 0) {
      RequestHelpers.putData(`/api/v1/users/${userId}`, getStateData())
        .then(res => {
          if(res.status === 200) {
            history.push("/admin/users");
          }
        })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getUser(); }, [])

  return(
    <div>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='pencil' /> Edit User
        </Header>
      </Segment>
      
      <Container className="table-container" fluid>
        <Message 
          hidden={errors.length === 0}
          negative
          header='Form Errors'
          list={errors}
        ></Message>
        <Segment inverted>
          <Form onSubmit={handleSubmit} inverted>
            <Form.Field required>
              <label>First name</label>
              <Input required onChange={handleOnChange} name="first_name" type='text' placeholder="First name" value={firstName} />
            </Form.Field>

            <Form.Field required>
              <label>Last name</label>
              <Input required onChange={handleOnChange} name="last_name" type='text' placeholder="Last name" value={lastName} />
            </Form.Field>

            <Form.Field required>
              <label>Email</label>
              <Input required onChange={handleOnChange} name="email" type='text' placeholder="Email" value={email} />
            </Form.Field>

            <Form.Field>
              <label>Password</label>
              <Input onChange={handleOnChange} name="password" type='password' placeholder="Password" />
            </Form.Field>

            <Form.Field>
              <label>Confirm Password</label>
              <Input onChange={handleOnChange} name="confirm_password" type='password' placeholder="Confirm Password" />
            </Form.Field>

            <Button fluid type='submit'>Submit</Button>
          </Form>
        </Segment>
      </Container>
    </div>
  )
}

export default EditUser;