import { Fragment, useEffect, useState } from 'react';
import RequestHelpers from '../../../helpers/requestHelpers';
import './index.css';
import { Table, Header, Container, Icon, Segment, Button, Modal, Loader, Dimmer } from 'semantic-ui-react'
import { useHistory, Link } from 'react-router-dom';

const DataRows = function(props) {
  const history                         = useHistory();
  const [confirmState, setConfirmState] = useState(false);
  const [confirmData, setConfirmData]   = useState(false);

  const handleEdit = (e) => {
    history.push(`/admin/products/${e.target.id}/edit`);
  }

  const showConfirm = (e) => {
    setConfirmData(e.target.id);
    setConfirmState(true);
  }
  
  const handleConfirm = () => {
    setConfirmState(false);
    RequestHelpers.deleteData(`/api/v1/products/${confirmData}`)
      .then(() => {
        props.getProducts();
      })
  }

  const handleCancel = () => {
    setConfirmState(false);
  }

  let data = [];
  if(Array.isArray(props.products))
  {
    props.products.forEach((product) => {
      data.push(
        <Fragment key={product._id}>
          <Table.Row>
            <Table.Cell>{product.name}</Table.Cell>
            <Table.Cell>{product.description}</Table.Cell>
            <Table.Cell>{product.upc}</Table.Cell>
            <Table.Cell>{product.sku}</Table.Cell>
            <Table.Cell>{product.stock}</Table.Cell>
            <Table.Cell textAlign="right">
              <Button className="table-action" id={product._id} icon onClick={handleEdit}>
                <Icon inverted id={product._id} name='pencil' />
              </Button>
              <Button className="table-action" id={product._id} icon onClick={showConfirm}>
                <Icon inverted id={product._id} name='trash' />
              </Button>
              <Modal open={confirmState} >
                <Modal.Header>Confirm Delete</Modal.Header>
                <Modal.Content image>
                  <Modal.Description>
                    <p>Are you sure?</p>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button color='black' onClick={handleCancel}>
                    Nope
                  </Button>
                  <Button
                    content="Confirm"
                    onClick={handleConfirm}
                  />
                </Modal.Actions>
              </Modal>
            </Table.Cell>
          </Table.Row>
        </Fragment>
      )
    })
  }

  return data;
}

const Products = function(props) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading]   = useState(true);

  const getProducts = () => {
    RequestHelpers.getData("/api/v1/products")
      .then(res => res.json())
      .then(results => {
        setProducts(results);
        setLoading(false);
    })
  }

  useEffect(() => {
    props.setActiveItem("manage_products");
    getProducts();
  }, [props])
  
  return(
    <div>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='tags' /> Products
        </Header>
        <Header textAlign="right" floated="right" inverted as='span'>
          <Link to="/admin/products/create">
            <Icon inverted name='plus' />
          </Link>
        </Header>
      </Segment>
      
      <Container className="table-container" fluid>
        <Segment>
          <Dimmer active={loading}>
            <Loader>Loading</Loader>
          </Dimmer>
        
          <Table inverted striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>UPC</Table.HeaderCell>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell>Stock</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <DataRows products={products} getProducts={getProducts} />
            </Table.Body>
          </Table>
        </Segment>
      </Container>
    </div>
  )
}

export default Products;
