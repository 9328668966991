import { useState, useEffect } from 'react';
import '../index.css';
import { Input, Message, Form, Button, Header, Container, Icon, Segment } from 'semantic-ui-react'
import RequestHelpers from '../../../../helpers/requestHelpers';
import { useHistory } from 'react-router-dom';

const EditEmployee = function(props) {
  const history                     = useHistory();
  const [employeeId, setEmployeeId] = useState('');
  const [firstName, setFirstName]   = useState('');
  const [lastName, setLastName]     = useState('');
  const [email, setEmail]           = useState('');
  const [identifier, setIdentifier] = useState('');
  const [errors, setErrors]         = useState([]);

  const getStateData = () => {
    return {
      first_name: firstName,
      last_name: lastName,
      email: email,
      identifier: identifier
    }
  }

  const getEmployee = () => {
    RequestHelpers.getData(`/api/v1/employees/${props.match.params.employeeId}`)
      .then((res) => { return(res.json()); })
      .then((result) => {
        setEmployeeId(result._id);
        setFirstName(result.first_name);
        setLastName(result.last_name);
        setEmail(result.email);
        setIdentifier(result.identifier);
      })
  }

  const handleOnChange = (e) => {
    switch(e.target.name) {
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "identifier":
        setIdentifier(e.target.value);
        break;
      default:
        break;
    }
  }

  const validEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return(re.test(String(email).toLowerCase()));
  }

  const handleValidation = () => {
    var errorList = [];
    setErrors([]);

    if(!validEmail()) {
      errorList.push("Email format is invalid");
    }

    setErrors(errorList);

    return errorList.length;
  }

  const handleSubmit = (e) => {
    if(handleValidation() === 0) {
      RequestHelpers.putData(`/api/v1/employees/${employeeId}`, getStateData())
        .then(res => {
          if(res.status === 200) {
            history.push("/admin/employees");
          }
        })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getEmployee(); }, [])

  return(
    <div>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='pencil' /> Edit Employee
        </Header>
      </Segment>
      
      <Container className="table-container" fluid>
        <Message 
          hidden={errors.length === 0}
          negative
          header='Form Errors'
          list={errors}
        ></Message>
        <Segment inverted>
          <Form onSubmit={handleSubmit} inverted>
            <Form.Field required>
              <label>First name</label>
              <Input required onChange={handleOnChange} name="first_name" type='text' placeholder="First name" value={firstName} />
            </Form.Field>

            <Form.Field required>
              <label>Last name</label>
              <Input required onChange={handleOnChange} name="last_name" type='text' placeholder="Last name" value={lastName} />
            </Form.Field>

            <Form.Field required>
              <label>Email</label>
              <Input required onChange={handleOnChange} name="email" type='text' placeholder="Email" value={email} />
            </Form.Field>

            <Form.Field required>
              <label>Identifier</label>
              <Input required onChange={handleOnChange} name="identifier" type='text' placeholder="Identifier" value={identifier} />
            </Form.Field>

            <Button fluid type='submit'>Submit</Button>
          </Form>
        </Segment>
      </Container>
    </div>
  )
}

export default EditEmployee;