import { Fragment, useEffect, useState } from 'react';
import RequestHelpers from '../../../helpers/requestHelpers';
import DateHelpers from '../../../helpers/dateHelpers';
import { Table, Header, Container, Icon, Segment, Button, Modal, Loader, Dimmer } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom';

const DataRows = function(props) {
  const history                         = useHistory();
  const [confirmState, setConfirmState] = useState(false);
  const [confirmData, setConfirmData]   = useState(false);
  
  const handleShow = (e) => {
    history.push(`/admin/checkouts/${e.target.id}`);
  }

  const showConfirm = (e) => {
    setConfirmData(e.target.id);
    setConfirmState(true);
  }
  
  const handleConfirm = () => {
    setConfirmState(false);
    RequestHelpers.deleteData(`/api/v1/checkout/${confirmData}`)
      .then(() => {
        props.getCheckouts();
      })
  }

  const handleCancel = () => {
    setConfirmState(false);
  }

  let data = [];
  props.checkouts.forEach((checkout) => {
    data.push(
      <Fragment key={checkout._id}>
        <Table.Row>
          <Table.Cell>{`${checkout.employeeId.first_name} ${checkout.employeeId.last_name}`}</Table.Cell>
          <Table.Cell>{checkout.products.length > 1 ? checkout.products.reduce((previousProduct, product) => {return(previousProduct.quantity + product.quantity)}) : checkout.products[0].quantity}</Table.Cell>
          <Table.Cell>{DateHelpers.formatDate(checkout.created_at)}</Table.Cell>
          <Table.Cell textAlign="right">
            <Button className="table-action" id={checkout._id} icon onClick={handleShow}>
              <Icon inverted id={checkout._id} name='eye' />
            </Button>
            <Button className="table-action" id={checkout._id} icon onClick={showConfirm}>
              <Icon inverted id={checkout._id} name='trash' />
            </Button>
            <Modal open={confirmState} >
              <Modal.Header>Confirm Delete</Modal.Header>
              <Modal.Content image>
                <Modal.Description>
                  <p>Are you sure?</p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={handleCancel}>
                  Nope
                </Button>
                <Button
                  content="Confirm"
                  onClick={handleConfirm}
                />
              </Modal.Actions>
            </Modal>
          </Table.Cell>
        </Table.Row>
      </Fragment>
    )
  })

  return data;
}

const Checkouts = function(props) {
  const [checkouts, setCheckouts] = useState([]);
  const [loading, setLoading]     = useState(true);

  const getCheckouts = () => {
    RequestHelpers.getData("/api/v1/checkout")
      .then(res => res.json())
      .then((results) => {
        if(results) {
          setCheckouts(results);
        }
        setLoading(false);
    })
  }

  useEffect(() => {
    props.setActiveItem("manage_checkouts");
    getCheckouts();
  }, [props])

  return(
    <div>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='cart' /> Checkouts
        </Header>
      </Segment>
      
      <Container className="table-container" fluid>
        <Segment>
          <Dimmer active={loading}>
            <Loader>Loading</Loader>
          </Dimmer>
        
          <Table inverted striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Product Count</Table.HeaderCell>
                <Table.HeaderCell>Created At</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <DataRows checkouts={checkouts} getCheckouts={getCheckouts} />
            </Table.Body>
          </Table>
        </Segment>
      </Container>
    </div>
  )
}

export default Checkouts;
