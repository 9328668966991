import * as React from 'react';
import Dashboard from './Dashboard';
import Products from './Products';
import CreateProduct from './Products/Create';
import InventoryProduct from './Products/Inventory';
import EditProduct from './Products/Edit';
import Employees from './Employees';
import CreateEmployee from './Employees/Create';
import EditEmployee from './Employees/Edit';
import Users from './Users';
import CreateUser from './Users/Create';
import EditUser from './Users/Edit';
import Checkouts from './Checkouts';
import ShowCheckout from './Checkouts/Show';
import './index.css';
import RequestHelpers from '../../helpers/requestHelpers';
import { Container, Menu } from 'semantic-ui-react';
import {
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom';

const Admin = function(props) {
  const history = useHistory();
  const [activeItem, setActiveItem] = React.useState('');

  const sendSupportEmail = () => {
    const mailto = "mailto:support@inventrack.app?subject=InvenTrack Support";
    window.location.href = mailto;
  }

  const handleItemClick = (e, { name }) => {
    window.scrollTo(0,0); 
    
    switch(name) {
      case "view_dashboard":
        history.push("/admin/dashboard");
        break;
      case "manage_products":
        history.push("/admin/products");
        break;
      case "create_product":
        history.push("/admin/products/create");
        break;
      case "inventory_products":
        history.push("/admin/products/inventory");
        break;
      case "manage_employees":
        history.push("/admin/employees");
        break;
      case "create_employee":
        history.push("/admin/employees/create");
        break;
      case "manage_users":
        history.push("/admin/users");
        break;
      case "create_user":
        history.push("/admin/users/create");
        break;
      case "manage_checkouts":
        history.push("/admin/checkouts");
        break;
      case "scanner_information":
        history.push("/admin/scanner_information");
        break;
      default:
        break
    }

    if(activeItem !== name) {
      setActiveItem(name);
    }
  };

  const handleCheckoutMode = function(e) {
    history.push("/checkout");
    e.preventDefault();
  }

  const handleLogout = function(e) {
    RequestHelpers.deleteData("/api/v1/auth", {})
      .then(res => {
        if(res.status === 200)
        {
          props.setLoggedIn(false);
        }
      })

    e.preventDefault();
  }

  return(
    <Container fluid className="Admin">
      <div>
        <Menu fixed="top" borderless inverted size="massive">
          <Menu.Item style={{fontFamily: "'Ubuntu', sans-serif", fontSize: "30px", paddingLeft: "52px", color: "#97d47d"}} name='logo'>
            InvenTrack
          </Menu.Item>

          <Menu.Menu position='right'>
            <Menu.Item
              name='checkout_mode'
              onClick={handleCheckoutMode}
            >
              Checkout Mode
            </Menu.Item>
            <Menu.Item
              name='logout'
              onClick={handleLogout}
            >
              Logout
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
      <div>
        <Menu fixed="left" borderless inverted vertical size="large">
          <Menu.Item>
            <Menu.Header>Dashboard</Menu.Header>

            <Menu.Menu>
            <Menu.Item
                name='view_dashboard'
                active={activeItem === 'view_dashboard'}
                onClick={handleItemClick}
              >View</Menu.Item>
            </Menu.Menu>
          </Menu.Item>
          
          <Menu.Item>
            <Menu.Header>Products</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name='manage_products'
                active={activeItem === 'manage_products'}
                onClick={handleItemClick}
              >Manage</Menu.Item>
              <Menu.Item
                name='create_product'
                active={activeItem === 'create_product'}
                onClick={handleItemClick}
              >Create</Menu.Item>
              <Menu.Item
                name='inventory_products'
                active={activeItem === 'inventory_products'}
                onClick={handleItemClick}
              >Inventory</Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header>Employees</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name='manage_employees'
                active={activeItem === 'manage_employees'}
                onClick={handleItemClick}
              >Manage</Menu.Item>
              <Menu.Item
                name='create_employee'
                active={activeItem === 'create_employee'}
                onClick={handleItemClick}
              >Create</Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header>Users</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name='manage_users'
                active={activeItem === 'manage_users'}
                onClick={handleItemClick}
              >Manage</Menu.Item>

              <Menu.Item
                name='create_user'
                active={activeItem === 'create_user'}
                onClick={handleItemClick}
              >Create</Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header>Checkouts</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name='manage_checkouts'
                active={activeItem === 'manage_checkouts'}
                onClick={handleItemClick}
              >Manage</Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          {/* <Menu.Item>
            <Menu.Header>Reporting</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name='manage_reporting'
                active={activeItem === 'manage_reporting'}
                onClick={handleItemClick}
              >Manage</Menu.Item>
            </Menu.Menu>
          </Menu.Item> */}

          <Menu.Item>
            <Menu.Header>Support</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name='scanner_information'
                active={activeItem === 'scanner_information'}
                onClick={handleItemClick}
              >
                Scanner Information
              </Menu.Item>

              <Menu.Item
                name='email'
                onClick={sendSupportEmail}
              >
                Send E-mail
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>
        </Menu>
      </div>
      <div className="admin-content">
        <Switch>
          <Route exact path="/admin">
            <Redirect to={props.path === "/" || props.path === "/login" ? "/admin/dashboard" : props.path || "/admin/dashboard"} />
          </Route>
          
          <Route exact path="/admin/dashboard">
            <Dashboard setActiveItem={setActiveItem} />
          </Route>

          <Route exact path="/admin/products">
            <Products setActiveItem={setActiveItem} />
          </Route>
          <Route exact path="/admin/products/create">
            <CreateProduct setActiveItem={setActiveItem} />
          </Route>
          <Route exact path="/admin/products/:productId/edit" component={EditProduct} />
          <Route exact path="/admin/products/inventory">
            <InventoryProduct setActiveItem={setActiveItem} />
          </Route>

          <Route exact path="/admin/employees">
            <Employees setActiveItem={setActiveItem} />
          </Route>
          <Route exact path="/admin/employees/create">
            <CreateEmployee setActiveItem={setActiveItem} />
          </Route>
          <Route exact path="/admin/employees/:employeeId/edit" component={EditEmployee} />

          <Route exact path="/admin/users">
            <Users setActiveItem={setActiveItem} />
          </Route>
          <Route exact path="/admin/users/create">
            <CreateUser setActiveItem={setActiveItem} />
          </Route>
          <Route exact path="/admin/users/:userId/edit" component={EditUser} />

          <Route exact path="/admin/checkouts">
            <Checkouts setActiveItem={setActiveItem} />
          </Route>
          <Route exact path="/admin/checkouts/:checkoutId" component={ShowCheckout} />
        </Switch>
      </div>
    </Container>
  )
}

export default Admin;
