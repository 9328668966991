import { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './index.css';
import { Table, Header, Container, Icon, Segment, Button, Modal, Dimmer, Loader } from 'semantic-ui-react'
import RequestHelpers from '../../../helpers/requestHelpers';

const UserRows = function(props) {
  const history                         = useHistory();
  const [confirmState, setConfirmState] = useState(false);
  const [confirmUser, setConfirmUser]   = useState(false);
  const handleEdit = (e) => {
    history.push(`/admin/users/${e.target.id}/edit`);
  }

  const showConfirm = (e) => {
    setConfirmUser(e.target.id);
    setConfirmState(true);
  }
  
  const handleConfirm = () => {
    setConfirmState(false);
    RequestHelpers.deleteData(`/api/v1/users/${confirmUser}`)
      .then(() => {
        props.getUsers();
      })
  }

  const handleCancel = () => {
    setConfirmState(false);
  }

  let data = [];
  props.users.forEach(user => {
    data.push(
      <Fragment key={user._id}>
        <Table.Row>
          <Table.Cell>{user.first_name}</Table.Cell>
          <Table.Cell>{user.last_name}</Table.Cell>
          <Table.Cell>{user.email}</Table.Cell>
          <Table.Cell textAlign="right">
            <Button className="table-action" id={user._id} icon onClick={handleEdit}>
              <Icon inverted id={user._id} name='pencil' />
            </Button>
            <Button className="table-action" id={user._id} icon onClick={showConfirm}>
              <Icon inverted id={user._id} name='trash' />
            </Button>
            <Modal
              open={confirmState}
            >
              <Modal.Header>Confirm Delete</Modal.Header>
              <Modal.Content image>
                <Modal.Description>
                  <p>Are you sure?</p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={handleCancel}>
                  Nope
                </Button>
                <Button
                  content="Confirm"
                  onClick={handleConfirm}
                />
              </Modal.Actions>
            </Modal>
          </Table.Cell>
        </Table.Row>
      </Fragment>
    )
  })

  return data;
}

const Users = function(props) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUsers = () => {
    RequestHelpers.getData("/api/v1/users")
      .then(res => res.json())
      .then(results => {
        setUsers(results);
        setLoading(false);
    })
  }

  useEffect(() => {
    props.setActiveItem("manage_users");
    getUsers();
  }, [props])

  return(
    <div>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='user' /> Users
        </Header>
        <Header textAlign="right" floated="right" inverted as='span'>
          <Link to="/admin/users/create">
            <Icon inverted name='plus' />
          </Link>
        </Header>
      </Segment>
      
      <Container className="table-container" fluid>
        <Segment>
          <Dimmer active={loading}>
            <Loader>Loading</Loader>
          </Dimmer>
          
          <Table inverted striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            
              
            <Table.Body>
              <UserRows users={users} getUsers={getUsers} />
            </Table.Body>
          </Table>
        </Segment>
      </Container>
    </div>
  )
}

export default Users;
