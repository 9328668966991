import { useEffect, useState } from "react";
import { Container, Segment, Header, Icon, Table } from "semantic-ui-react";
import RequestHelpers from "../../../../helpers/requestHelpers";

const Products = (props) => {
  let productRows = [];

  props.products.forEach((product) => {
    productRows.push(
      <Table.Row>
        <Table.Cell>{product.name}</Table.Cell>
        <Table.Cell>{product.description}</Table.Cell>
        <Table.Cell>{product.upc}</Table.Cell>
        <Table.Cell>{product.quantity}</Table.Cell>
      </Table.Row>
    )
  })

  return productRows;
}

const ShowCheckout = (props) => {
  const [checkout, setCheckout] = useState({employeeId: {}, products: []});

  useEffect(() => {
    RequestHelpers.getData(`/api/v1/checkout/${props.match.params.checkoutId}`)
      .then((res) => { return(res.json()); })
      .then((result) => {
        setCheckout(result);
      })
  }, [props])

  return(
    <Container>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='eye' /> View Checkout
        </Header>
      </Segment>

      <Container>
        <Segment clearing>
          <Table inverted compact fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell><b>Employee:</b> {checkout.employeeId.first_name} {checkout.employeeId.last_name}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table inverted compact fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>UPC</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Products products={checkout.products} />
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell>Total: {checkout.products.length > 1 ? checkout.products.reduce((previousProduct, product) => {return(previousProduct.quantity + product.quantity)}) : (checkout.products.length > 0 ? checkout.products[0].quantity : "")}</Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
      </Container>
    </Container>
  )
}

export default ShowCheckout;