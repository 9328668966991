import './index.css';
import React, { useState } from 'react';
import { 
  Message,
  Header,
  Sticky,
  Form,
  Button,
  Divider,
  Step,
  Icon,
  Segment,
  Loader,
  Table,
  Dimmer,
  Modal,
  Input,
  Container,
  Grid
} from 'semantic-ui-react';
import RequestHelpers from '../../helpers/requestHelpers';

const Cart = (props) => {
  let items = [];

  props.products.forEach((product) => {
    if(props.isEditable) {
      items.push(
        <Table.Row className="cart-row" key={product.id}>
          <Table.Cell>{product.name}</Table.Cell>
          <Table.Cell>{product.description}</Table.Cell>
          <Table.Cell>{product.upc}</Table.Cell>
          <Table.Cell>{product.quantity}</Table.Cell>
          <Table.Cell>
            <Button onClick={() => { props.setProduct(product); props.setOpen(true); setTimeout(() => {document.getElementById("quantity").focus();}, 50) }}>Change Quantity</Button>
            <Button id={product.id} onClick={props.removeProduct}>
              <Icon id={product.id} color="black" name="trash" />
            </Button>
            </Table.Cell>
        </Table.Row>
      )
    }
    else
    {
      items.push(
        <Table.Row key={product.id}>
          <Table.Cell>{product.name}</Table.Cell>
          <Table.Cell>{product.description}</Table.Cell>
          <Table.Cell>{product.upc}</Table.Cell>
          <Table.Cell>{product.quantity}</Table.Cell>
        </Table.Row>
      )
    }
  })

  return items;
}

const Checkout = (props) => {
  const [step, setStep]                         = useState(1);
  const [upcLoading, setUpcLoading]             = useState(false);
  const [hideHeader, setHideHeader]             = useState(false);
  const [employeeName, setEmployeeName]         = useState(null);
  const [employeeId, setEmployeeId]             = useState(null);
  const [employeeNotFound, setEmployeeNotFound] = useState(false);
  const [upcNotFound, setUpcNotFound]           = useState(false);
  const [upc, setUpc]                           = useState(null);
  const [products, setProducts]                 = useState([]);
  const [product, setProduct]                   = useState({});
  const [quantity, setQuantity]                 = useState("1");
  const [open, setOpen]                         = useState(false);

  const resetCheckout = () => {
    setEmployeeNotFound(false);
    setUpcNotFound(false);
    setProducts([]);
    setEmployeeId(null);
    setEmployeeName(null);
    setUpc(null);
    setHideHeader(false);
    document.getElementById('employeeId').value = '';
    setStep(1);
    document.getElementById('employeeId').focus();
  }

  const getData = () => {
    return {
      employeeId: employeeId,
      products: products
    }
  }

  const handleInput = (e) => {
    switch(e.target.name) {
      case 'employeeId':
        setEmployeeId(e.target.value);
        break;
      case 'upc':
        setUpc(e.target.value);
        break;
      default:
        break;
    }
  }

  const handleEmployeeSubmit = (e) => {
    setStep(0);
    getEmployee()
      .then((res) => {
        if(res.status === 404) {
          return({error: true});
        } 
        else
        {
          return(res.json());
        }
      })
      .then((result) => {
        if(result.error) {
          setEmployeeNotFound(true);
          document.getElementById('employeeId').value = '';
          setStep(1);
          document.getElementById('employeeId').focus();
        }
        else
        {
          setEmployeeNotFound(false);
          setEmployeeName(result.first_name);
          setEmployeeId(result.id);
          setStep(2);
          document.getElementById('upc').focus();
        }
      })
  }

  const handleOnQuantityChange = (e) => {
    setQuantity(e.target.value);
  }

  const handleAddRemove = (e) => {
    let updatedProduct = product;
    if(e.target.id === "add") {
      
    }

    if(e.target.id === "remove") {
    
    }
  }

  const handleKeyPadPress = (e) => {
    if(document.getElementById("quantity").value === "0") {
      document.getElementById("quantity").value = "";
    }

    if(document.getElementById("quantity").value.length === 1 && document.getSelection().baseNode === document.getElementById("quantity").parentElement && document.getSelection().type === "Range") {
      document.getElementById("quantity").value = "";
    }

    if(parseInt(e.target.innerHTML) >= 0 && parseInt(e.target.innerHTML) <= 9) {
      document.getElementById("quantity").value = document.getElementById("quantity").value+e.target.innerHTML;
    }

    if(e.target.innerHTML.startsWith('<i') || e.target.parentElement.innerHTML.startsWith('<i')) {
      document.getElementById("quantity").value = document.getElementById("quantity").value.substring(0, document.getElementById("quantity").value.length - 1);
    }

    if(document.getElementById("quantity").value === "") {
      document.getElementById("quantity").value = "0";
    }

    let newProduct = product
    newProduct.quantity = document.getElementById("quantity").value;
  }

  const handleOnOpen = () => {
    setOpen(true);
  }

  const handleOnClose = () => {
    document.getElementById("upc").value = "";
    document.getElementById("upc").focus();
    setOpen(false);
  }

  const handleCancel = (e) => {
    let newProducts = (products.filter(function( obj ) {
      return obj.quantity !== "0";
    }))
    console.log(newProducts);
    setProducts(newProducts);
    setOpen(false);
  }

  const handleQuantityKeyUp = (e) => {
    if(e.target.value.length === 0 && e.which === 8) {
      e.target.value = 0;
    }
  }

  const handleQuantityKeyDown = (e) => {
    console.log(e);
    if(e.which >= 48 && e.which <= 57) {
      if(e.target.value.length === 1 && e.target.value === "0") {
        e.target.select();
      }
    }
  }

  const handleUpcSubmit = (e) => {
    setUpcLoading(true);
    let items = products;
    let existingItem = null;
    setUpc(e.target.value);
    getProduct()
      .then((res) => {
        if(res.status === 404) {
          return({error: true});
        }
        else
        {
          return(res.json());
        }
      })
      .then((result) => {
        if(result.error) {
          setUpcNotFound(true);
          document.getElementById('upc').value = '';
          setUpcLoading(false);
          document.getElementById('upc').focus();
        }
        else
        {
          setUpcNotFound(false);
          existingItem = items.find(obj => obj.id === result.id);

          if(existingItem) {
            existingItem.quantity++;
          }
          else
          {
            result.quantity = 1;
            items.push(result);
          }

          setProducts(items);
          document.getElementById('upc').value = '';
          setUpcLoading(false);
          document.getElementById('upc').focus();
        }
      })
  }

  const handleSubmitCart = (e) => {
    if(products.length === 0) {
      alert("Cart cannot be empty!");
    }
    else
    {
      setStep(0);
      setTimeout(() => {
        setStep(3);
      }, 1000)
    }
  }

  const handleUpdateCart = (e) => {
    setStep(0);
    setTimeout(() => {
      setUpcNotFound(false);
      document.getElementById('upc').value = '';
      setStep(2);
      document.getElementById('upc').focus();
    }, 500);
  }


  const handleSubmitCartConfirm = (e) => {
    setHideHeader(true);
    setStep(0);

    let data = getData();
    console.log(data);
    RequestHelpers.postData('/api/v1/checkout', data)
      .then((res) => {
        return(res.json());
      })
      .then((result) => {
        setTimeout(() => {
          setStep(4);
          setTimeout(() => {
            resetCheckout();
          }, 3000);
        }, 1000);
      })

    setTimeout(() => {
      setStep(4);
      setTimeout(() => {
        resetCheckout();
      }, 3000);
    }, 3000);
  }

  const getEmployee = () => {
    return RequestHelpers.getData(`/api/v1/employees/lookup/${employeeId}`)
  }

  const getProduct = () => {
    return RequestHelpers.getData(`/api/v1/products/lookup/${upc}`)
  }

  const removeProduct = (e) => {
    console.log(e.target.id);
    let newProducts = products.filter((p) => {
      return p.id !== e.target.id;
    })

    setProducts(newProducts);
  }

  return(
    <React.Fragment>
      <div className='Checkout'>
        <Sticky hidden={hideHeader}>
          <Step.Group widths={3}>
            <Step disabled={step !== 1} active={step === 1}>
              <Icon name='address card' />
              <Step.Content>
                <Step.Title>Scan ID</Step.Title>
              </Step.Content>
            </Step>
            <Step disabled={step !== 2} active={step === 2}>
              <Icon name='cart arrow down' />
              <Step.Content>
                <Step.Title>Scan Supplies</Step.Title>
              </Step.Content>
            </Step>
            <Step disabled={step !== 3} active={step === 3}>
              <Icon name='info' />
              <Step.Content>
                <Step.Title>Confirm Order</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </Sticky>

        <div hidden={step !== 0}>
          <Loader size='massive' active content='Loading' />
        </div>
        
        <Segment hidden={step !== 1} raised>
          <Form onSubmit={handleEmployeeSubmit} inverted size='large'>
            <Form.Group widths='equal'>
              <Form.Input
                id="employeeId"
                name='employeeId'
                label='Employee ID'
                placeholder='XXXXXXXXX'
                autoFocus
                onChange={handleInput}
              />
            </Form.Group>
            <Divider hidden />
          </Form>

          <Message hidden={!employeeNotFound} negative>
            <Message.Header>Employee ID Not Found</Message.Header>
            <p>Please check with your administrator</p>
          </Message>
        </Segment>

        <Segment hidden={step !== 2} raised>
          <Dimmer active={upcLoading}>
            <Loader>Loading</Loader>
          </Dimmer>

          <Form onSubmit={handleUpcSubmit} inverted size='large'>
            <Form.Group widths='equal'>
              <Form.Input
                id='upc'
                name='upc'
                label='Scan UPC'
                placeholder='XXXXXXXXX'
                onChange={handleInput}
              />
            </Form.Group>
            <Divider hidden />
          </Form>

          <Modal onClose={handleOnClose} onOpen={handleOnOpen} open={open} >
            <Modal.Header>Add/Remove Quantity</Modal.Header>
            <Modal.Content className="InventoryModal">
              <Modal.Description>
                <Header textAlign="center" as="h4">
                  {product.name}
                </Header>
                <Divider />
                <Input className="quantity" type="number" id="quantity" onChange={handleOnQuantityChange} onKeyDown={handleQuantityKeyDown} onKeyUp={handleQuantityKeyUp} fluid size="massive" defaultValue={product.quantity} />
                <Divider />
                <Container>
                  <Grid columns={3} divided>
                    <Grid.Row>
                      <Grid.Column>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">1</Button>
                        </Segment>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">4</Button>
                        </Segment>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">7</Button>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">2</Button>
                        </Segment>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">5</Button>
                        </Segment>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">8</Button>
                        </Segment>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">0</Button>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">3</Button>
                        </Segment>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">6</Button>
                        </Segment>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">9</Button>
                        </Segment>
                        <Segment textAlign="center">
                          <Button onClick={handleKeyPadPress} fluid size="big">
                            <Icon name="long arrow alternate left" color="black" />
                          </Button>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button fluid color='black' onClick={handleCancel}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>

          <Message hidden={!upcNotFound} negative>
            <Message.Header>UPC Not Found</Message.Header>
            <p>Please check with your administrator</p>
          </Message>

          <Table inverted striped fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>UPC</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Cart isEditable={true} setQuantity={setQuantity} removeProduct={removeProduct} setProduct={setProduct} setOpen={setOpen} products={products} />
            </Table.Body>
          </Table>
          <Button onClick={handleSubmitCart} fluid>Submit</Button>
        </Segment>

        <Segment hidden={step !== 3} raised>
          <Table inverted striped fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>UPC</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Cart isEditable={false} products={products} />
            </Table.Body>
          </Table>
          <Button onClick={handleUpdateCart} fluid>Update Cart</Button>
          <Divider />
          <Button onClick={handleSubmitCartConfirm} fluid>Confirm</Button>
        </Segment>

        <Segment hidden={step !== 4} raised>
          <Header as='h1'>
            Thank You {employeeName}!
          </Header>
        </Segment>
      </div>
    </React.Fragment>
  )
}

export default Checkout;