import './index.css';
import { Loader } from 'semantic-ui-react';

const Loading = (props) => {
  return(
    <div className="Loading">
      <Loader inverted active size='massive'></Loader>
    </div>
  )
}

export default Loading;