import React, { useState } from "react";
import "./index.css";
import RequestHelpers from "../../helpers/requestHelpers";
import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react"

const Login = function(props) {
  const [email, setEmail]           = useState("");
  const [password, setPassword]     = useState("");
  const [showError, setShowError]   = useState(false);
  const [error, setError]           = useState({});

  const handleEmail = function(e) {
    setEmail(e.target.value);
  }

  const handlePassword = function(e) {
    setPassword(e.target.value);
  }

  const handleSubmit = function(e) {
    RequestHelpers.postData("/api/v1/auth", {email: email, password: password})
      .then(res => {
        if(res.status === 404)
        {
          setShowError(true);
        }
        else if(res.status === 403)
        {
          setShowError(true);
        }
        else if(res.status === 200)
        {
          props.setLoggedIn(true);
        }

        return(res.json());
      })
      .then((result) => {
        setError(result.errors);
      })

    e.preventDefault();
  }

  return (
    <React.Fragment>
      <div className="Login">
        <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
          <Grid.Column style={{maxWidth: 450}}>
            <Header className="Header" as="h1" textAlign="center">
              InvenTrack Login
            </Header>
            <Form onSubmit={handleSubmit} size="large">
              <Segment className="Segment" stacked>
                <Message hidden={!showError} negative
                  header={error.name}
                  list={[error.message]}
                ></Message>

                <Form.Input 
                  fluid icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  onChange={handleEmail}
                  className="Input"
                  required
                />

                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={handlePassword}
                  required
                />

                <Button fluid size="large">
                  Login
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default Login;