import { useState, useEffect } from 'react';
import '../index.css';
import { Message, Form, Button, Header, Container, Icon, Segment } from 'semantic-ui-react'
import RequestHelpers from '../../../../helpers/requestHelpers';
import { useHistory } from 'react-router-dom';

const CreateProduct = function(props) {
  const history                       = useHistory();
  const [name, setName]               = useState('');
  const [description, setDescription] = useState('');
  const [upc, setUpc]                 = useState('');
  const [sku, setSku]                 = useState('');
  const [stock, setStock]             = useState('0');
  const [errors, setErrors]           = useState([]);

  const getStateData = () => {
    return {
      name: name,
      description: description,
      upc: upc,
      sku: sku,
      stock: stock
    }
  }

  const handleOnChange = (e) => {
    switch(e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "description":
        setDescription(e.target.value);
        break;
      case "upc":
        setUpc(e.target.value);
        break;
      case "sku":
        setSku(e.target.value);
        break;
      case "stock":
        setStock(e.target.value);
        break;
      default:
        break;
    }
  }

  const handleValidation = () => {
    var errorList = [];
    setErrors([]);

    // Validate Here

    setErrors(errorList);

    return errorList.length;
  }

  const handleSubmit = (e) => {
    if(handleValidation() === 0) {
      RequestHelpers.postData("/api/v1/products", getStateData())
        .then(res => {
          if(res.status === 200) {
            history.push("/admin/products");
          }
        })
    }
  }

  useEffect(() => {
    props.setActiveItem("create_product");
  }, [props])

  return(
    <div>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='plus' /> Create Product
        </Header>
      </Segment>
      
      <Container className="table-container" fluid>
        <Message 
          hidden={errors.length === 0}
          negative
          header='Form Errors'
          list={errors}
        ></Message>
        <Segment inverted>
          <Form onSubmit={handleSubmit} inverted>
            <Form.Input onChange={handleOnChange} required fluid label='Name' placeholder='Name' name='name' />
            <Form.Input onChange={handleOnChange} fluid label='Description' placeholder='Description' name='description' />
            <Form.Input onChange={handleOnChange} required fluid label='UPC' placeholder='UPC' name='upc' />
            <Form.Input onChange={handleOnChange} fluid label='SKU' placeholder='SKU' name='sku' />
            <Form.Input required onChange={handleOnChange} fluid type='number' defaultValue="0" label='Stock' placeholder='Stock' name='stock' />
            <Button primary fluid type='submit'>Submit</Button>
          </Form>
        </Segment>
      </Container>
    </div>
  )
}

export default CreateProduct;
