import { useState, useEffect } from 'react';
import '../index.css';
import { Input, Message, Form, Button, Header, Container, Icon, Segment } from 'semantic-ui-react'
import RequestHelpers from '../../../../helpers/requestHelpers';
import { useHistory } from 'react-router-dom';

const EditProduct = function(props) {
  const history = useHistory();
  const [productId, setProductId]     = useState('');
  const [name, setName]               = useState('');
  const [description, setDescription] = useState('');
  const [upc, setUpc]                 = useState('');
  const [sku, setSku]                 = useState('');
  const [stock, setStock]             = useState('');
  const [errors, setErrors]           = useState([]);

  const getStateData = () => {
    return {
      name: name,
      description: description,
      upc: upc,
      sku: sku,
      stock: stock
    }
  }

  const getProduct = () => {
    RequestHelpers.getData(`/api/v1/products/${props.match.params.productId}`)
      .then((res) => { return(res.json()); })
      .then((result) => {
        setProductId(result._id);
        setName(result.name);
        setDescription(result.description);
        setUpc(result.upc);
        setSku(result.sku);
        setStock(result.stock);
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getProduct(); },[])

  const handleOnChange = (e) => {
    switch(e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "description":
        setDescription(e.target.value);
        break;
      case "upc":
        setUpc(e.target.value);
        break;
      case "sku":
        setSku(e.target.value);
        break;
      case "stock":
        setStock(e.target.value);
        break;
      default:
        break;
    }
  }

  const handleValidation = () => {
    var errorList = [];
    setErrors([]);

    // Validate Here

    setErrors(errorList);

    return errorList.length;
  }

  const handleSubmit = (e) => {
    if(handleValidation() === 0) {
      RequestHelpers.putData(`/api/v1/products/${productId}`, getStateData())
        .then(res => {
          if(res.status === 200) {
            history.push("/admin/products");
          }
        })
    }
  }

  return(
    <div>
      <Segment className="header-segment" clearing>
        <Header floated="left" inverted as='h2'>
          <Icon inverted name='pencil' /> Edit Product
        </Header>
      </Segment>
      
      <Container className="table-container" fluid>
        <Message 
          hidden={errors.length === 0}
          negative
          header='Form Errors'
          list={errors}
        ></Message>
        <Segment inverted>
          <Form onSubmit={handleSubmit} inverted>
            <Form.Field required>
              <label>Name</label>
              <Input required onChange={handleOnChange} name="name" type='text' placeholder="Name" defaultValue={name} />
            </Form.Field>

            <Form.Field>
              <label>Description</label>
              <Input onChange={handleOnChange} name="description" type='text' placeholder="Description" defaultValue={description} />
            </Form.Field>

            <Form.Field required>
              <label>UPC</label>
              <Input required onChange={handleOnChange} name="upc" type='text' placeholder="UPC" defaultValue={upc} />
            </Form.Field>
            
            <Form.Field>
              <label>SKU</label>
              <Input onChange={handleOnChange} name="sku" type='text' placeholder="SKU" defaultValue={sku} />
            </Form.Field>

            <Form.Field required>
              <label>Stock</label>
              <Input required onChange={handleOnChange} name="stock" type='number' placeholder="Stock" defaultValue={stock} />
            </Form.Field>

            <Button fluid type='submit'>Submit</Button>
          </Form>
        </Segment>
      </Container>
    </div>
  )
}

export default EditProduct;